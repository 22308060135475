import React from 'react'

const IconGift = () => (
  <svg
    width="52"
    height="56"
    viewBox="0 0 52 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25.519" cy="30.4809" r="25.519" fill="#FEEDE4" />
    <path
      d="M5.54274 17.4081H13.4556C13.6533 17.4078 13.8441 17.4815 13.9902 17.6147C14.1363 17.7479 14.2272 17.931 14.2451 18.1279C14.263 18.3249 14.2065 18.5213 14.0867 18.6787C13.9669 18.836 13.7926 18.9428 13.598 18.9781L13.4556 18.9907H5.54274C5.34501 18.9911 5.1543 18.9174 5.00818 18.7842C4.86205 18.651 4.7711 18.4679 4.75324 18.2709C4.73537 18.074 4.79188 17.8775 4.91165 17.7202C5.03141 17.5628 5.20574 17.456 5.40031 17.4208L5.54274 17.4081H13.4556H5.54274ZM8.14766 8.14376C8.27878 8.01292 8.45166 7.93223 8.63616 7.91575C8.82066 7.89926 9.0051 7.94803 9.15734 8.05355L9.26812 8.14534L15.5952 14.4756C15.7334 14.6156 15.8144 14.8021 15.8226 14.9986C15.8307 15.1951 15.7654 15.3876 15.6393 15.5386C15.5133 15.6895 15.3355 15.7881 15.1407 15.8152C14.9459 15.8422 14.748 15.7957 14.5856 15.6847L14.4748 15.5929L8.14766 9.26264C7.99932 9.11425 7.91598 8.91302 7.91598 8.7032C7.91598 8.49338 7.99932 8.29215 8.14766 8.14376ZM18.2033 4.74756C18.3883 4.74787 18.5672 4.81294 18.7092 4.93147C18.8511 5.05 18.9471 5.2145 18.9804 5.39641L18.993 5.53885V13.4517C18.9913 13.648 18.9167 13.8366 18.7837 13.981C18.6507 14.1254 18.4688 14.2151 18.2733 14.2329C18.0779 14.2506 17.8828 14.1951 17.7259 14.0771C17.5691 13.959 17.4617 13.7869 17.4247 13.5941L17.412 13.4517V5.53885C17.412 5.32898 17.4954 5.12772 17.6438 4.97932C17.7922 4.83093 17.9935 4.74756 18.2033 4.74756Z"
      fill="#FFA67D"
    />
    <path
      d="M35.9708 23.5439H33.0568C33.7387 22.5472 34.0013 21.3301 33.7897 20.1475C33.578 18.965 32.9086 17.9087 31.9215 17.1999C30.9344 16.4912 29.7063 16.185 28.4945 16.3454C27.2826 16.5059 26.181 17.1205 25.4203 18.0607C24.6597 17.1205 23.5581 16.5059 22.3462 16.3454C21.1344 16.185 19.9063 16.4912 18.9192 17.1999C17.9321 17.9087 17.2627 18.965 17.051 20.1475C16.8394 21.3301 17.102 22.5472 17.7839 23.5439H14.8699C14.3104 23.5444 13.774 23.7629 13.3784 24.1513C12.9828 24.5397 12.7603 25.0664 12.7598 25.6157V29.7594C12.7603 30.3088 12.9828 30.8354 13.3784 31.2239C13.774 31.6123 14.3104 31.8308 14.8699 31.8313V42.1906C14.8704 42.74 15.0929 43.2666 15.4885 43.6551C15.8841 44.0435 16.4205 44.2619 16.98 44.2625H33.8607C34.4202 44.2619 34.9566 44.0435 35.3522 43.6551C35.7478 43.2666 35.9703 42.74 35.9708 42.1906V31.8313C36.5303 31.8308 37.0667 31.6123 37.4623 31.2239C37.8579 30.8354 38.0804 30.3088 38.0809 29.7594V25.6157C38.0804 25.0664 37.8579 24.5397 37.4623 24.1513C37.0667 23.7629 36.5303 23.5444 35.9708 23.5439ZM26.4754 20.954C26.4754 20.4418 26.6301 19.9411 26.9199 19.5152C27.2097 19.0893 27.6217 18.7574 28.1036 18.5613C28.5856 18.3653 29.1159 18.314 29.6276 18.414C30.1392 18.5139 30.6092 18.7605 30.9781 19.1227C31.347 19.4849 31.5982 19.9464 31.7 20.4488C31.8017 20.9512 31.7495 21.4719 31.5499 21.9451C31.3502 22.4183 31.0122 22.8228 30.5784 23.1074C30.1446 23.392 29.6347 23.5439 29.113 23.5439H26.4754V20.954ZM21.7277 18.3642C22.427 18.365 23.0974 18.6381 23.5918 19.1236C24.0863 19.6092 24.3645 20.2674 24.3653 20.954V23.5439H21.7277C21.0281 23.5439 20.3572 23.271 19.8626 22.7853C19.3679 22.2996 19.0901 21.6409 19.0901 20.954C19.0901 20.2672 19.3679 19.6084 19.8626 19.1227C20.3572 18.6371 21.0281 18.3642 21.7277 18.3642ZM14.8699 25.6157H24.3653V29.7594H14.8699V25.6157ZM16.98 31.8313H24.3653V42.1906H16.98V31.8313ZM33.8618 42.1906H26.4754V31.8313H33.8607L33.8618 42.1906ZM26.4754 29.7594V25.6157H35.9708L35.9719 29.7594H26.4754Z"
      fill="#F08769"
    />
  </svg>
)

export default IconGift
