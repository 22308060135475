import { Skeleton } from '@acctglobal/skeleton'
import type { SearchSort, State } from '@faststore/sdk/dist/types'
import type {
  Filter_FacetsFragment,
  IStoreSelectedFacet,
} from '@generated/graphql'
import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import type { ProductType } from 'src/components/product/ProductCard/ProductCardTypes'
import ProductGrid from 'src/components/product/ProductGrid'
import { useCustomGalleryQuery } from 'src/components/sections/ProductGallery/useGalleryQuery'
import { useTotalCount } from 'src/components/sections/ProductGallery/useTotalCount'
import { ITEMS_PER_PAGE } from 'src/constants'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import { widthSkeletonProductGrid } from '../product/ProductGrid/ProductGrid'
import { CustomGalleryEmptyPage } from './CustomGalleryEmptyPage'

type CustomGalleryPageProps = {
  isLayoutList: boolean
  page: number
  selectedFacets: IStoreSelectedFacet[]
  sort: SearchSort
  setHasMorePages: Dispatch<SetStateAction<boolean>>
  setIsLoadingMoreProducts: Dispatch<SetStateAction<boolean>>
  setTotalCount: Dispatch<SetStateAction<number | undefined>>
  setFilteredFacets: Dispatch<SetStateAction<Filter_FacetsFragment[]>>
  setIsGalleryEmpty: Dispatch<SetStateAction<boolean>>
  totalPages: number
}

export const CustomGalleryPage = ({
  isLayoutList,
  page,
  selectedFacets,
  sort,
  setHasMorePages,
  setTotalCount,
  totalPages,
  setIsLoadingMoreProducts,
  setFilteredFacets,
  setIsGalleryEmpty,
}: CustomGalleryPageProps) => {
  const [products, setProducts] = useState<ProductType[] | null>(null)
  const { width } = useWindowDimensions()

  const state: State = {
    base: '',
    page,
    selectedFacets,
    term: '',
    sort,
  }

  const { data, error } = useCustomGalleryQuery({
    state,
    itemsPerPage: ITEMS_PER_PAGE,
  })

  const totalCount = useTotalCount(data)

  useEffect(() => {
    if (error) {
      setHasMorePages(false)
      setIsLoadingMoreProducts(false)

      return
    }

    if (!data?.search) {
      setProducts(null)
      setIsLoadingMoreProducts(true)

      return
    }

    const result = data.search.products?.edges.map((product) => product.node)

    if (result?.length === 0) {
      setIsGalleryEmpty(true)
    } else {
      setIsGalleryEmpty(false)
    }

    setProducts(result)
    setIsLoadingMoreProducts(false)
    setTotalCount(totalCount)

    setFilteredFacets(data.search.facets)

    if (data.search.products?.pageInfo?.hasNextPage) {
      setHasMorePages(true)
    } else {
      setHasMorePages(false)
    }
  }, [
    data,
    error,
    totalCount,
    page,
    totalPages,
    setTotalCount,
    setHasMorePages,
    setIsLoadingMoreProducts,
    setFilteredFacets,
    setIsGalleryEmpty,
  ])

  if (error) {
    return <></>
  }

  if (products?.length === 0) {
    return <CustomGalleryEmptyPage />
  }

  return (
    <>
      {products ? (
        <ProductGrid
          products={products}
          page={page}
          pageSize={ITEMS_PER_PAGE}
          layoutGridOrList={isLayoutList}
          sort={sort}
        />
      ) : (
        <Skeleton
          table={widthSkeletonProductGrid(width)}
          width={width <= 1200 ? width : 1224}
          height={400}
          backgroundColor="#F4F4F4"
        />
      )}
    </>
  )
}
