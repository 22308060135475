import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'

import { FifthQuestion } from './FifthQuestion'
import DataValidate from '../DataValidate'

const ThirdStep = () => {
  const { quizState } = useBuyQuizContext()
  const { priceRange, name, email, olderThanEighteen } = quizState

  return !priceRange || (name && email && !olderThanEighteen) ? (
    <div className="buy-quiz-thirdStep-container">
      <FifthQuestion />
    </div>
  ) : (
    <DataValidate />
  )
}

export default ThirdStep
