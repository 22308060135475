import React from 'react'
import { CheckBoxInput, CheckedBoxInput } from 'src/images/buy-quiz/icons'

interface PolicyProps {
  setAgeSelected: React.Dispatch<React.SetStateAction<boolean>>
  ageSelected: boolean
}

const AgePolicyCheckbox = ({ setAgeSelected, ageSelected }: PolicyProps) => {
  const handleSelectAge = (
    event: React.MouseEvent<HTMLButtonElement, Event>
  ) => {
    event.preventDefault()

    setAgeSelected(!ageSelected)
  }

  return (
    <div className="NameQuestion-policy">
      <button className="NameQuestion-policy-icon" onClick={handleSelectAge}>
        {ageSelected ? <CheckedBoxInput /> : <CheckBoxInput />}
      </button>
      <label className="NameQuestion-policy-label">
        Declaro ter analisado e compreendido a{' '}
        <a
          href="https://www.vivara.com.br/institucional/politica-privacidade"
          target="_blank"
          className="NameQuestion-policy-label-emphasis"
          rel="noreferrer"
        >
          Política de Privacidade
        </a>{' '}
        e tenho mais de 18 anos de idade.
      </label>
    </div>
  )
}

export default AgePolicyCheckbox
