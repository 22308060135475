import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { MaleIcon, FemaleIcon } from 'src/images/buy-quiz/icons'

const FirstQuestion = () => {
  const { dispatch } = useBuyQuizContext()

  const handleClick = (heOrShe: 'Ele' | 'Ela') => {
    dispatch({ payload: { property: 'heOrShe', value: heOrShe } })
  }

  return (
    <div className="firstQuestion-container">
      <span className="firstQuestion-question">Quem você vai presentear?</span>
      <div className="firstQuestion-optionsContainer">
        <button
          className="firstQuestion-option"
          onClick={() => handleClick('Ela')}
        >
          <FemaleIcon />
          <span className="firstQuestion-option-span">Ela</span>
        </button>
        <button
          className="firstQuestion-option"
          onClick={() => handleClick('Ele')}
        >
          <MaleIcon />
          <span className="firstQuestion-option-span">Ele</span>
        </button>
      </div>
    </div>
  )
}

export default FirstQuestion
