import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import LayoutGridList from 'src/components/sections/ProductGallery/LayoutGridList'
import { Skeleton } from '@acctglobal/skeleton'
import type {
  Filter_FacetsFragment,
  IStoreSelectedFacet,
} from '@generated/graphql'
import type { SearchSort } from '@faststore/sdk/dist/types'
import { ITEMS_PER_PAGE } from 'src/constants'

import { CustomGalleryPage } from './CustomGalleryPage'

import './styles.scss'

type ProductGalleryQuizProps = {
  selectedFacets: IStoreSelectedFacet[]
  sort: SearchSort
  setFilteredFacets: Dispatch<SetStateAction<Filter_FacetsFragment[]>>
  isGalleryEmpty: boolean
  setIsGalleryEmpty: Dispatch<SetStateAction<boolean>>
}

export const CustomGallery = ({
  isGalleryEmpty,
  selectedFacets,
  setIsGalleryEmpty,
  sort,
  setFilteredFacets,
}: ProductGalleryQuizProps) => {
  const [pages, setPages] = useState<number[]>([0])
  const [hasMorePages, setHasMorePages] = useState<boolean>(false)
  const [isLoadingMoreProducts, setIsLoadingMoreProducts] =
    useState<boolean>(false)

  const [totalCount, setTotalCount] = useState<number | undefined>()
  const [layout, setLayout] = useState('grid')
  const isLayoutList = layout === 'list'

  const handleChangeLayout = (value: string) => {
    setLayout(value)
  }

  const loadMore = () => {
    if (isLoadingMoreProducts) {
      return
    }

    if (totalCount && pages.length < Math.ceil(totalCount / ITEMS_PER_PAGE)) {
      setIsLoadingMoreProducts(true)
      setPages((prev) => [...prev, pages.length])
    }
  }

  useEffect(() => {
    setPages([0])
  }, [selectedFacets, sort])

  return (
    <div id="custom-gallery" className="product-listing / grid-content-full">
      <div className="product-listing__content-grid / grid-content">
        {!isGalleryEmpty && (
          <div className="customGallery--container-total">
            {totalCount && !isLoadingMoreProducts ? (
              <>
                <LayoutGridList
                  layout={layout}
                  handleChangeLayout={handleChangeLayout}
                />
                <p className="customGallery--total">{`${totalCount} produtos`}</p>
              </>
            ) : (
              <Skeleton width={90} height={16} backgroundColor="#F4F4F4" />
            )}
          </div>
        )}
        {pages.map((page, index) => (
          <CustomGalleryPage
            key={`custom-page-${index}`}
            isLayoutList={isLayoutList}
            page={page}
            selectedFacets={selectedFacets}
            sort={sort}
            setTotalCount={setTotalCount}
            setHasMorePages={setHasMorePages}
            totalPages={pages.length}
            setIsLoadingMoreProducts={setIsLoadingMoreProducts}
            setFilteredFacets={setFilteredFacets}
            setIsGalleryEmpty={setIsGalleryEmpty}
          />
        ))}
      </div>
      {hasMorePages && (
        <button className="customGallery--button" onClick={loadMore}>
          {isLoadingMoreProducts ? 'CARREGANDO...' : 'CARREGAR MAIS PRODUTOS'}
        </button>
      )}
    </div>
  )
}
