import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'

const QuestionNumberInfo = () => {
  const { quizState } = useBuyQuizContext()

  const questions = [
    !quizState.heOrShe,
    !quizState.giftedPerson,
    !quizState.ageGroup,
    !quizState.style,
    !quizState.priceRange,
    !quizState.email || !quizState.name || !quizState.olderThanEighteen,
  ]

  let questionIdentifier = ''

  questions.forEach((question, index) => {
    if (question && !questionIdentifier) {
      if (index < 4) {
        questionIdentifier = `Pergunta 0${index + 1}`
      } else if (index === 4) {
        questionIdentifier = 'última pergunta'
      } else {
        questionIdentifier = 'estamos quase lá'
      }
    }
  })

  return (
    <div
      className={`buy-quiz-questionNumber-container ${
        questionIdentifier === 'estamos quase lá' &&
        'buy-quiz-questionNumber-container-ending'
      }`}
    >
      <span
        className={`buy-quiz-questionNumber-span ${
          quizState.name && quizState.olderThanEighteen && 'last-step'
        } `}
      >
        {quizState.name && quizState.olderThanEighteen && quizState.priceRange
          ? quizState.name
          : questionIdentifier}
      </span>
      <div className="buy-quiz-questionNumber-line" />
    </div>
  )
}

export default QuestionNumberInfo
