import React from 'react'

import femaleIcon from './femaleIcon.svg'
import maleIcon from './maleIcon.svg'
import breadcrumbArrow from './breadcrumbArrow.svg'
import breadcrumbArrowSelected from './breadcrumbArrowSelected.svg'
import breadcrumbBigArrow from './breadcrumbBigArrow.svg'
import breadcrumbBigArrowSelected from './breadcrumbBigArrowSelected.svg'
import buttonArrow from './buttonArrow.svg'
import checkBoxInput from './checkBoxInput.svg'
import checkedBoxInput from './checkedBoxInput.svg'

export const MaleIcon = () => <img src={maleIcon} alt="male-icon" />

export const FemaleIcon = () => <img src={femaleIcon} alt="female-icon" />

export const BreadcrumbArrow = () => (
  <img src={breadcrumbArrow} alt="breadcrumbArrow-icon" />
)

export const BreadcrumbArrowSelected = () => (
  <img src={breadcrumbArrowSelected} alt="breadcrumbArrowSelected-icon" />
)

export const BreadcrumbBigArrow = () => (
  <img src={breadcrumbBigArrow} alt="breadcrumbBigArrow-icon" />
)

export const BreadcrumbBigArrowSelected = () => (
  <img src={breadcrumbBigArrowSelected} alt="breadcrumbBigArrowSelected-icon" />
)

export const ButtonArrow = () => (
  <img src={buttonArrow} alt="buttonArrow-icon" />
)

export const CheckBoxInput = () => (
  <img src={checkBoxInput} alt="checkBoxInput-icon" />
)

export const CheckedBoxInput = () => (
  <img src={checkedBoxInput} alt="checkedBoxInput-icon" />
)
