import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'

import firstStyleHe from '../../../images/buy-quiz/quizz-classico.png'
import secondStyleHe from '../../../images/buy-quiz/quizz-esportivo.png'
import thirdStyleHe from '../../../images/buy-quiz/quizz-criativo.png'
import fourthStyleHe from '../../../images/buy-quiz/quizz-moderno.png'
import firstStyleShe from '../../../images/buy-quiz/quizz-classica.png'
import secondStyleShe from '../../../images/buy-quiz/quizz-romantica.png'
import thirdStyleShe from '../../../images/buy-quiz/quizz-minimalista.png'
import fourthStyleShe from '../../../images/buy-quiz/quizz-moderna.png'
import ThirdStep from '../ThirdStep'
import { StyleChoiceButton } from './StyleChoiceButton'

const FourthQuestion = () => {
  const { quizState } = useBuyQuizContext()
  const { heOrShe, style } = quizState
  const isShe = heOrShe === 'Ela'

  const collectionDetails = [
    {
      img: isShe ? firstStyleShe : firstStyleHe,
      value: isShe ? 'Classica' : 'Classico',
      title: isShe ? 'Clássica' : 'Clássico',
      description:
        'Assim como uma joia, atravessa os anos sem nunca sair de moda.',
    },
    {
      img: isShe ? secondStyleShe : secondStyleHe,
      value: isShe ? 'Romantica' : 'Esportivo',
      title: isShe ? 'Romântica' : 'Esportivo',
      description: isShe
        ? 'Estilo apaixonado pela delicadeza e leveza dos detalhes.'
        : 'Foco em conforto e praticidade, mas sem esquecer a elegância.',
    },
    {
      img: isShe ? thirdStyleShe : thirdStyleHe,
      value: isShe ? 'Minimalista' : 'Criativo',
      title: isShe ? 'Minimalista' : 'Criativo',
      description: isShe
        ? 'O poder da simplicidade, onde menos pode ser muito mais.'
        : 'Sem regras, o que importa é se expressar sem medo de ousar.',
    },
    {
      img: isShe ? fourthStyleShe : fourthStyleHe,
      value: isShe ? 'Moderna' : 'Moderno',
      title: isShe ? 'Moderna' : 'Moderno',
      description:
        'Perfeita combinação entre o que é clássico com o que é tedência.',
    },
  ]

  return !style ? (
    <div className="buy-quiz-secondStep-container">
      <span className="fourthQuestion-question">
        Você definiria o estilo da pessoa como:
      </span>
      <div className="fourthQuestion-optionsContainer">
        {collectionDetails.map((collection) => (
          <StyleChoiceButton key={collection.value} collection={collection} />
        ))}
      </div>
    </div>
  ) : (
    <ThirdStep />
  )
}

export default FourthQuestion
