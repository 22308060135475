import type { IStoreSelectedFacet } from '@generated/graphql'

type CollectionMap = {
  [style: string]: { [gender: string]: string } | string
}

const collectionMap: CollectionMap = {
  Baby: {
    Ela: '2408',
    Ele: '2408',
  },
  Kids: '2409',
  Teen: {
    Ela: '2438',
    Ele: '2438',
  },
  Classico: '2427',
  Classica: '2411',
  Criativo: '2433',
  Romantica: '2415',
  Esportivo: '2430',
  Minimalista: '2418',
  Moderno: '2436',
  Moderna: '2422',
}

export const createDefaultFacets = (
  quizState: BuyQuizProps
): IStoreSelectedFacet[] => {
  if (
    !quizState.heOrShe ||
    !quizState.priceRange ||
    !quizState.ageGroup ||
    !quizState.style
  ) {
    return []
  }

  const facets: IStoreSelectedFacet[] = []

  const [minValue, maxValue] = quizState.priceRange.split(':')

  facets.push({ key: 'price', value: `${minValue}-to-${maxValue}` })

  const styleKey: keyof CollectionMap = quizState.style

  const collectionId =
    (collectionMap[styleKey] as { [gender: string]: string })?.[
      quizState.heOrShe
    ] || (collectionMap[styleKey] as string)

  facets.push({
    key: 'productClusterIds',
    value: collectionId,
  })

  return facets
}
