import React from 'react'
import { graphql } from 'gatsby'
import { BuyQuizProvider } from 'src/contexts/buy-quiz-context'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import BuyQuiz from 'src/components/BuyQuiz'

const Page = () => {
  return (
    <>
      <GatsbySeo
        title="Vivara, Quiz!"
        description="Guided Buy Quiz"
        language="pt-BR"
        noindex
        nofollow
      />

      <BuyQuizProvider>
        <BuyQuiz />
      </BuyQuizProvider>
    </>
  )
}

export const query = graphql`
  query BuyQuizPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
