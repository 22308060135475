import React from 'react'

export const CustomGalleryEmptyPage = () => {
  return (
    <div className="customGalleryEmptyPage--container">
      <h1> Infelizmente não encontramos resultados para a sua busca</h1>
      <div className="customGalleryEmptyPage--intructions">
        <h3>Ao realizar nova busca, você pode: </h3>
        <ul className="customGalleryEmptyPage--blank-list">
          <li>verificar a ortografia da palavra</li>
          <li>usar apenas uma palavra</li>
          <li>buscar por termos genéricos</li>
          <li>tentar o uso de sinônimos</li>
        </ul>
      </div>
    </div>
  )
}
