import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'

import FirstQuestion from './FirstQuestion'
import SecondQuestion from './SecondQuestion'
import ThirdQuestion from './ThirdQuestion'
import imageFirstStepDesktop from '../../../images/buy-quiz/firststep-desktop.png'
import imageFirstStepMobile from '../../../images/buy-quiz/firststep-mobile.png'
import SecondStep from '../SecondStep'

const FirstStep = () => {
  const { quizState } = useBuyQuizContext()

  const { giftedPerson, heOrShe, ageGroup } = quizState

  return !ageGroup ? (
    <div className="buy-quiz-firstStep-container">
      <div className="buy-quiz-firstStep-question">
        {!heOrShe ? (
          <FirstQuestion />
        ) : !giftedPerson ? (
          <SecondQuestion />
        ) : (
          <ThirdQuestion />
        )}
      </div>
      <div className="buy-quiz-firstStep-image-desk">
        <img src={imageFirstStepDesktop} alt="firstStepDesktop" />
      </div>
      <div className="buy-quiz-firstStep-image-mobile">
        <img src={imageFirstStepMobile} alt="firstStepMobile" />
      </div>
    </div>
  ) : (
    <SecondStep />
  )
}

export default FirstStep
