import React, { useEffect } from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useCampaignContext } from 'src/contexts/campaign-context'

import BreadCrumb from './BreadCrumb'
import QuestionNavigator from './QuestionNavigator'
import FirstStep from './FirstStep'
import QuestionNumberInfo from './QuestionNumberInfo'
import { QuizResult } from './QuizResult'

import './style.scss'

const BuyQuiz = () => {
  const { quizState } = useBuyQuizContext()
  const { setCampaignPage } = useCampaignContext()

  const shouldShowQuizResult =
    quizState.olderThanEighteen &&
    quizState.ageGroup &&
    quizState.email &&
    quizState.giftedPerson &&
    quizState.heOrShe &&
    quizState.name &&
    quizState.priceRange &&
    quizState.style

  useEffect(() => {
    if (shouldShowQuizResult) {
      window?.sessionStorage?.setItem('resultQuiz', JSON.stringify(quizState))
    }
  }, [quizState, shouldShowQuizResult])

  setCampaignPage(false)

  return (
    <>
      {shouldShowQuizResult ? (
        <QuizResult />
      ) : (
        <div className="buy-quiz-container">
          <BreadCrumb />
          <QuestionNavigator />
          <QuestionNumberInfo />
          <FirstStep />
        </div>
      )}
    </>
  )
}

export default BuyQuiz
