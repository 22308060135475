import axios from 'axios'

export const saveQuizData = async (props: BuyQuizProps) => {
  const variables = {
    ...props,
  }

  try {
    const { data } = await axios.post(
      '/api/saveQuizGuidedShopping',
      variables,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return data
  } catch (error) {
    console.error(error)

    throw new Error(`Um erro ocorreu: ${error.message}`)
  }
}
