import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'

import CloseIcon from '../icons/Close'
import IconButton from '../ui/IconButton/IconButton'

const QuestionNavigator = () => {
  const { quizState, dispatch } = useBuyQuizContext()
  const isThereLastAnswer = !!quizState.priceRange

  const question = [
    quizState.heOrShe,
    quizState.giftedPerson,
    quizState.ageGroup,
    quizState.style,
  ]

  const properties = ['heOrShe', 'giftedPerson', 'ageGroup']

  const questionAnswers = question.filter((answer) => !!answer)

  const handleClick = (key: QuizActionProperty) => {
    dispatch({ payload: { property: key, value: null } })
  }

  return (
    <>
      {questionAnswers.length > 0 && !isThereLastAnswer && (
        <div className="buy-quiz-questionNavigator-container">
          {questionAnswers.map((answer, index) => (
            <div key={answer} className="buy-quiz-questionNavigator-item">
              <span className="buy-quiz-questionNavigator-property">
                {answer}
              </span>
              {questionAnswers.length < 3 && (
                <IconButton
                  icon={<CloseIcon color="#F08769" />}
                  aria-label="button-close"
                  onClick={() =>
                    handleClick(properties[index] as QuizActionProperty)
                  }
                  classes="buy-quiz-questionNavigator-button-remove"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default QuestionNavigator
