import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'

type StyleChoiceButtonProps = {
  collection: { img: string; title: string; value: string; description: string }
}

export const StyleChoiceButton = ({ collection }: StyleChoiceButtonProps) => {
  const { dispatch } = useBuyQuizContext()
  const handleClick = (styleChoice: string) => {
    dispatch({ payload: { property: 'style', value: styleChoice } })
  }

  return (
    <button
      className="fourthQuestion-option"
      onClick={() => handleClick(collection.value)}
    >
      <img
        className="fourthQuestion-option-image"
        src={collection.img}
        alt="style-classic"
      />
      <span className="fourthQuestion-option-title">{collection.title}</span>
      <p className="fourthQuestion-option-description">
        {collection.description}
      </p>
    </button>
  )
}
