import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'

import { NameQuestion } from './NameQuestion'
import imageThirdStepDesktop from '../../../images/buy-quiz/thirdstep-desktop.png'
import imageThirdStepMobile from '../../../images/buy-quiz/thirdstep-mobile.png'
import { EmailQuestion } from './EmailQuestion'

const DataValidate = () => {
  const { quizState } = useBuyQuizContext()

  const { name, email, olderThanEighteen } = quizState

  return !olderThanEighteen || !name || !email ? (
    <div className="buy-quiz-dataValidate-container">
      <div className="buy-quiz-dataValidate-question">
        {!name ? <NameQuestion /> : !email && <EmailQuestion />}
      </div>
      <div className="buy-quiz-dataValidate-image-desk">
        <img src={imageThirdStepDesktop} alt="dataValidateDesktop" />
      </div>
      <div className="buy-quiz-dataValidate-image-mobile">
        <img src={imageThirdStepMobile} alt="dataValidateMobile" />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default DataValidate
