import React from 'react'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import {
  BreadcrumbArrow,
  BreadcrumbArrowSelected,
  BreadcrumbBigArrow,
  BreadcrumbBigArrowSelected,
} from 'src/images/buy-quiz/icons'

const BreadCrumb = () => {
  const { quizState } = useBuyQuizContext()
  const { heOrShe, giftedPerson, ageGroup, style, priceRange } = quizState
  const isFirstStepActivated = !ageGroup || (ageGroup && style && priceRange)
  const isSecondStepActivated =
    (ageGroup && !style) || (ageGroup && style && priceRange)

  const isThirdStepActivated =
    (heOrShe && giftedPerson && ageGroup && style && priceRange) ||
    (heOrShe && giftedPerson && ageGroup && style && !priceRange)

  return (
    <div className="buy-quiz-breadCrumb-container">
      <span
        className={`buy-quiz-breadCrumb-spanText ${
          isFirstStepActivated && 'activated'
        }`}
      >
        Quem vai presentear
      </span>
      <div className="buy-quiz-breadCrumb-iconMobile">
        {isFirstStepActivated ? (
          <BreadcrumbArrowSelected />
        ) : (
          <BreadcrumbArrow />
        )}
      </div>
      <div className="buy-quiz-breadCrumb-iconDesktop">
        {isFirstStepActivated ? (
          <BreadcrumbBigArrowSelected />
        ) : (
          <BreadcrumbBigArrow />
        )}
      </div>
      <span
        className={`buy-quiz-breadCrumb-spanText ${
          isSecondStepActivated && 'activated'
        }`}
      >
        Estilo do presenteado
      </span>
      <div className="buy-quiz-breadCrumb-iconMobile">
        {isSecondStepActivated ? (
          <BreadcrumbArrowSelected />
        ) : (
          <BreadcrumbArrow />
        )}
      </div>
      <div className="buy-quiz-breadCrumb-iconDesktop">
        {isSecondStepActivated ? (
          <BreadcrumbBigArrowSelected />
        ) : (
          <BreadcrumbBigArrow />
        )}
      </div>
      <span
        className={`buy-quiz-breadCrumb-spanText ${
          isThirdStepActivated && 'activated'
        }`}
      >
        Faixa de preço
      </span>
    </div>
  )
}

export default BreadCrumb
