import React, { useEffect, useState } from 'react'
import './product-grid.scss'
import { Skeleton } from '@acctglobal/skeleton'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import ProductCard from 'src/components/product/ProductCard'
import { isStorageAvailable } from 'src/utils/isStorageAvailable'
import WishListInsiderUpdater from 'src/components/wishlist/WishListInsiderUpdater'
import findMissingProductIdsOnInsiderWishlistObject from 'src/contexts/WishlistContext/utils/findMissingProductIdsOnInsiderWishlistObject'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'

import type { ProductType } from '../ProductCard/ProductCardTypes'
import {
  gridOrderByStock,
  reorderingProductBySku,
  waitForElementById,
} from './utils'

interface Props {
  products: ProductType[]
  page: number
  pageSize: number
  isLoading?: boolean
  layoutGridOrList?: boolean
  setIsButtonLoading?: React.Dispatch<React.SetStateAction<boolean>>
  sort: string
}

function cleanSession() {
  if (isStorageAvailable()) {
    sessionStorage.removeItem('clickedProductId')
  }
}

async function scrollToHashElement(hash: string | null, offset: number) {
  if (!hash) {
    return
  }

  try {
    const elementToScroll = await waitForElementById(hash)
    const elementTop = +elementToScroll.getBoundingClientRect()?.top ?? 0
    const scrollY = +window?.scrollY ?? 0

    window?.scrollTo({
      top: elementTop + scrollY - offset,
      behavior: 'smooth',
    })

    setTimeout(() => {
      cleanSession()
    }, 500)
  } catch (error) {
    console.error('Could not scroll to element:', error)
  }
}

export function widthSkeletonProductGrid(widthView?: number) {
  return widthView
    ? widthView < 768
      ? { rows: 2, columns: 2 }
      : widthView < 1279
      ? { rows: 2, columns: 3 }
      : { rows: 2, columns: 4 }
    : undefined
}

function ProductGrid({
  products,
  page,
  pageSize,
  isLoading = false,
  layoutGridOrList,
  setIsButtonLoading,
  sort,
}: Props) {
  const [filterProducts, setFilterProducts] = useState<ProductType[]>()
  const [hash, setHash] = useState<string | null>(null)
  const [loadingSkeleton, setLoadingSkeleton] = useState(isLoading)
  const [productIdsForInsiderUpdate, setProductIdsForInsiderUpdate] = useState<
    string[]
  >([])

  const { lists } = useWishlistContext()
  const { width } = useWindowDimensions()

  useEffect(
    (offset = 280) => {
      if (isStorageAvailable()) {
        sessionStorage.getItem('clickedProductId') &&
          setHash(sessionStorage.getItem('clickedProductId'))
      }

      scrollToHashElement(hash, offset)
    },
    [hash]
  )

  useEffect(() => {
    if (!window.insider_object.wishlist) {
      window.insider_object.wishlist = {
        id: '',
        currency: 'BRL',
        total: window.insider_object?.wishlist?.total ?? 0,
        line_items: window.insider_object?.wishlist?.line_items ?? [],
      }
    }

    const missingProductIds =
      findMissingProductIdsOnInsiderWishlistObject(lists)

    if (missingProductIds.length === 0) {
      return
    }

    setProductIdsForInsiderUpdate(missingProductIds)
  }, [lists])

  useEffect(() => {
    let newProduct

    if (sort === 'price_asc') {
      newProduct = reorderingProductBySku(products)

      newProduct.sort(
        (
          a: { offers: { lowPrice: number } },
          b: { offers: { lowPrice: number } }
        ) => {
          if (a?.offers?.lowPrice === 0) {
            return 1
          }

          if (b?.offers?.lowPrice === 0) {
            return -1
          }

          return a?.offers?.lowPrice - b?.offers?.lowPrice
        }
      )
    } else if (sort === 'price_desc') {
      newProduct = reorderingProductBySku(products)

      newProduct.sort(
        (
          a: { offers: { lowPrice: number } },
          b: { offers: { lowPrice: number } }
        ) => {
          if (a?.offers?.lowPrice === 0) {
            return 1
          }

          if (b?.offers?.lowPrice === 0) {
            return -1
          }

          return b?.offers?.lowPrice - a?.offers?.lowPrice
        }
      )
    } else {
      newProduct = gridOrderByStock(products)
    }

    setFilterProducts(newProduct)
    setLoadingSkeleton(false)
  }, [products])

  useEffect(() => {
    return setIsButtonLoading?.(false)
  }, [])

  return (
    <>
      {loadingSkeleton ? (
        <Skeleton
          table={widthSkeletonProductGrid(width)}
          width={width <= 1200 ? width : 1224}
          height={400}
          backgroundColor="#F4F4F4"
        />
      ) : (
        <>
          {productIdsForInsiderUpdate.map((productId) => (
            <WishListInsiderUpdater productId={productId} key={productId} />
          ))}
          <ul
            className={`product-grid ${
              layoutGridOrList === true ? 'list-card' : ''
            }`}
          >
            {filterProducts?.map((product, idx) => {
              if (
                product?.id === filterProducts[filterProducts.length - 1]?.id &&
                setIsButtonLoading
              ) {
                setIsButtonLoading(false)
              }

              return (
                <li id={product.id} key={`${product.id}${idx}-card`}>
                  <ProductCard
                    product={product}
                    index={pageSize * page + idx + 1}
                    bordered
                    outOfStock={
                      product?.offers?.offers?.[0]?.availability !==
                      'https://schema.org/InStock'
                    }
                    variant="listing"
                  />
                </li>
              )
            })}
          </ul>
        </>
      )}
    </>
  )
}

export default ProductGrid
