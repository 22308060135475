import React, { useState } from 'react'
import Button from 'src/components/ui/Button/Button'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { ArrowRightIcon } from 'src/images/svg/components/ArrowIcons'

const options = [{ label: 'Outros:', value: 'Outros' }]

const femaleOptions = [
  { label: 'Namorada / Esposa', value: 'Namorada / Esposa' },
  { label: 'Mãe', value: 'Mãe' },
  { label: 'Avó', value: 'Avó' },
  { label: 'Amiga', value: 'Amiga' },
  {
    label: 'Filha / Sobrinha / Afilhada',
    value: 'Filha / Sobrinha / Afilhada',
  },
]

const maleOptions = [
  { label: 'Namorado / Esposo', value: 'Namorado / Esposo' },
  { label: 'Pai', value: 'Pai' },
  { label: 'Avô', value: 'Avô' },
  { label: 'Amigo', value: 'Amigo' },
  {
    label: 'Filho / Sobrinho / Afilhado',
    value: 'Filho / Sobrinho / Afilhado',
  },
]

const REGEX_EMPTY_STRING = /^(?!\s*$).+/

const SecondQuestion = () => {
  const { dispatch, quizState } = useBuyQuizContext()
  const [optionSelected, setOptionSelected] = useState<string>('')
  const [inputTextValue, setInputTextValue] = useState<string>('')
  const { heOrShe } = quizState

  const onChange = (value: string) => {
    setOptionSelected(value)
  }

  const handleClick = () => {
    const value = optionSelected === 'Outros' ? inputTextValue : optionSelected

    dispatch({ payload: { property: 'giftedPerson', value } })
  }

  const validateInputs = (): boolean => {
    if (!optionSelected) {
      return false
    }

    if (optionSelected === 'Outros') {
      const inputTextValidate = REGEX_EMPTY_STRING.test(inputTextValue)

      if (!inputTextValidate || inputTextValue.length > 20) {
        return false
      }
    }

    return true
  }

  return (
    <div className="secondQuestion-container">
      <span className="secondQuestion-question">Quem você irá presentear?</span>
      <div className="secondQuestion-options">
        {[...(heOrShe === 'Ele' ? maleOptions : femaleOptions), ...options].map(
          (option) => (
            <div
              className={`secondQuestion-option-item ${
                option.value === 'Outros' && option.value !== optionSelected
                  ? 'hidden'
                  : ''
              }${option.value === 'Outros' ? ' option-other' : ''}`}
              key={option.value}
            >
              <input
                className="secondQuestion-option-input"
                id={option.value}
                type="radio"
                value={option.value}
                checked={option.value === optionSelected}
                onChange={() => onChange(option.value)}
              />
              <label
                className="secondQuestion-option-label"
                htmlFor={option.value}
              >
                {option.label}
              </label>
              {option.value === 'Outros' && option.value === optionSelected && (
                <input
                  type="text"
                  placeholder="Escreva aqui"
                  className="secondQuestion-option-input-text"
                  value={inputTextValue}
                  onChange={(event) => setInputTextValue(event.target.value)}
                  maxLength={20}
                />
              )}
            </div>
          )
        )}
      </div>
      <Button
        variant="primary"
        icon={<ArrowRightIcon size={{ width: 15, height: 15 }} />}
        iconPosition="right"
        disabled={!validateInputs()}
        onClick={validateInputs() ? handleClick : undefined}
      >
        Prosseguir
      </Button>
    </div>
  )
}

export default SecondQuestion
